import "../../pages/index.css";
import "../SignUp/styles.css";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import Card from "./components/Card";
import { accounting_firm, company_icon } from "../../constants/images";
import { updateUser, userLogin } from "../../repositories/userRepository";
import { getUserLS, setUserLS } from "../../modules/localStorage";
import SubmitButton from "./components/SubmitButton";
import { decodeJWTToken } from "../../repositories/tokenRepository";
import { createOrganization } from "../../repositories/organizationRepository";
import { LoadingOverlay } from "@mantine/core";

const cards = [
  {
    image: company_icon,
    title: "Business",
    content:
      "Seamlessly manage all your cashflow scenarios and make better decisions",
    className: "hover-card",
  },
  {
    image: accounting_firm,
    content: "Provide your clients with advisory services and grow your firm",
    title: "Accounting Firm",
    className: "hover-card",
  },
  {
    image: "/assets/images/suitcase-cfo.svg",
    content:
      "Manage your clients effectively and enable them to make better decisions",
    title: "Portfolio CFO",
    className: "hover-card",
  },
];

function TellUsWhoYouAre() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([
    "user",
    "xeroConnection",
    "xeroOrganization",
  ]);
  const [loading, setLoading] = useState(true); // Start with loading state

  useEffect(() => {
    const accountType = localStorage.getItem("userAccountTypeOption");
    if (accountType) {
      handleExplore(accountType);
    }
  }, []);

  const setUpDemoCompany = async (accountType: string) => {
    const user = getUserLS();
    let companyName = `${user?.firstName}'s Demo Company`;
    const active = user?.addMore ? false : true;
    const {
      success,
      response: { id, name, createdAt },
    } = await createOrganization({
      companyName,
      isDemo: true,
      currentlyActive: active,
      accountType,
    });
    if (!success) {
      return;
    }
    localStorage.setItem("organizationId", id);
    setUserLS({
      organizationId: id,
      organizationName: name,
      isDemo: true,
      completedTutorial: false,
      createdAt,
    });
    await updateUser({ lastStage: "you-are-all-set-up" });
  };

  const handleExplore = async (accountType: string) => {
    setLoading(true);
    const user = getUserLS();
    const addMore = user.addMore || false;
    localStorage.setItem("startTrial", "true");
    if (addMore) {
      navigate("/tell-us-about-your-business");
    } else {
      await setUpDemoCompany(accountType);
      const token = localStorage.getItem("token") || "";
      await updateUser({
        accountType: accountType,
        lastStage: "you-are-all-set-up",
      });

      if (token) {
        const { email, password } = await decodeJWTToken(token);
        await userLogin(email, password);
      }
      // setLoading(false);

      window.location.href = "cashflow?tab=forecast";
    }
  };

  return (
    <div
      className="card-outer-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <LoadingOverlay visible={loading} />
      {/* 
      <p className="what-we-do-title is-justify-self-flex-start">
        Choose an account that best reflects your organization
      </p>
      <div className="card-container mt-6" style={{ width: "40%" }}>
        {cards.map(({ title, image, content, className }, index) => (
          <Card
            key={index}
            title={title}
            image={image}
            content={content}
            className={className}
          />
        ))}
      </div> */}
    </div>
  );
}

export default TellUsWhoYouAre;
