import axios from "axios";
import { uniqBy } from "lodash";
import React, { useState } from "react";
import { configs } from "../../../constants";
import {
  formatCashflowMapping,
  formatCashflowMappingInInvoices,
} from "../../../modules/formatCashflowMapping";

export const useRecords = ({ billId, isGlobalContext }: any) => {
  const [records, setRecords] = React.useState<any[]>([]);
  const [recordsReceivable, setRecordsReceivable] = React.useState<any[]>([]);
  const [selectedRecord, selectRecord] = React.useState<any>(undefined);
  const [externalAccounts, setExternalAccounts] = React.useState<any[]>([]);
  const [recordsLoading, setRecordsLoading] = useState(false);
  const [contactsLoading, setContactsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState({
    totalPayable: 0,
    totalReceivable: 0,
    totalContacts: 0,
  });

  /**
   *
   * @param billId
   * @param skip
   * @param type
   * @param sortField
   * @param sortOrder
   * @returns
   */

  const getRecords = async (
    billId?: any,
    skip?: number,
    type?: string,
    sortField?: string,
    sortOrder?: string,
    filterObj: any = {},
    isNew?: boolean
  ) => {
    const { searchText, contacts, startDate, endDate, status, buckets } =
      filterObj;
    setRecordsLoading(true);
    switch (type) {
      case "Payable":
        const { data: recData } = await axios.post(
          configs.urls.BASE_URL +
            `/invoice/getAllPayables?searchText=${searchText}&contacts=${contacts}&startDate=${startDate}&endDate=${endDate}&status=${status}&buckets=${buckets}`,
          {
            skip: skip,
            sortField,
            sortOrder,
          },
          {
            withCredentials: true,
          }
        );
        if (recData.success) {
          const { bills: _bills, total } = recData.response;
          let bills = formatCashflowMappingInInvoices(_bills);

          setTotalRecords((prev) => ({
            ...prev,
            totalPayable: total,
          }));

          if (skip) {
            if (isNew) {
              setRecords((prev) => uniqBy([...bills], "id"));
            } else {
              setRecords((prev) => uniqBy([...prev, ...bills], "id"));
            }
          } else setRecords(uniqBy(bills, "id"));
          billId &&
            selectRecord(bills.find((record: any) => record.id == billId));
        }
        break;
      case "Receivable":
        const { data: payData } = await axios.post(
          configs.urls.BASE_URL +
            `/invoice/getAllReceivables?searchText=${searchText}&contacts=${contacts}&startDate=${startDate}&endDate=${endDate}&status=${status}&buckets=${buckets}`,
          {
            skip: skip,
            sortField,
            sortOrder,
          },
          {
            withCredentials: true,
          }
        );
        if (payData.success) {
          const { invoices: _invoices, total } = payData.response;
          let invoices = formatCashflowMappingInInvoices(_invoices);

          setTotalRecords((prev) => ({
            ...prev,
            totalReceivable: total,
          }));
          if (skip) {
            if (isNew) {
              setRecordsReceivable((prev) => uniqBy([...invoices], "id"));
            } else {
              setRecordsReceivable((prev) =>
                uniqBy([...prev, ...invoices], "id")
              );
            }
          } else {
            setRecordsReceivable(uniqBy(invoices, "id"));
          }
        }
        break;
    }

    if (type) {
      setRecordsLoading(false);
      return;
    }

    const { data: payData } = await axios.post(
      configs.urls.BASE_URL +
        `/invoice/getAllReceivables?searchText=${searchText}&&contacts=${contacts}&&startDate=${startDate}&&endDate=${endDate}`,
      {
        skip: 0,
      },
      {
        withCredentials: true,
      }
    );
    if (payData.success) {
      const { invoices: _invoices, total } = payData.response;
      let invoices = formatCashflowMappingInInvoices(_invoices);

      setTotalRecords((prev) => ({
        ...prev,
        totalReceivable: total,
      }));

      setRecordsReceivable(invoices);
    }
    const { data: recData } = await axios.post(
      configs.urls.BASE_URL +
        `/invoice/getAllPayables?searchText=${searchText}&&contacts=${contacts}&&startDate=${startDate}&&endDate=${endDate}`,
      {
        skip: 0,
      },
      {
        withCredentials: true,
      }
    );
    if (recData.success) {
      const { bills: _bills, total } = recData.response;
      let bills = formatCashflowMappingInInvoices(_bills);

      setTotalRecords((prev) => {
        return {
          ...prev,
          totalPayable: total,
        };
      });

      setRecords(bills);
      billId && selectRecord(bills.find((record: any) => record.id == billId));
    }

    setRecordsLoading(false);
  };

  const fetchExternalAccounts = async (
    skip?: number,
    sortOrder?: string,
    sortField?: string,
    searchText?: string,
    contacts?: string[],
    activeTab?: string,
    filterObj: any = {}
  ) => {
    const { startDate, endDate, filterType } = filterObj;
    setContactsLoading(true);
    axios
      .get(
        configs.urls.BASE_URL +
          `/contact/getAll/${
            skip || 0
          }?sortOrder=${sortOrder}&sortField=${sortField}&searchText=${searchText}&contacts=${contacts}&type=${activeTab}&startDate=${startDate}&endDate=${endDate}&filterType=${filterType}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          let contacts = formatCashflowMapping(res.data.response.contacts);

          if (skip) setExternalAccounts((prev) => [...prev, ...contacts]);
          else setExternalAccounts(contacts);

          setTotalRecords((prev) => ({
            ...prev,
            totalContacts: res.data.response.total,
          }));
        }
      });
    setContactsLoading(false);
  };

  const getBillWithId = async (id: any) => {
    if (!id) return;
    axios.defaults.withCredentials = true;
    axios
      .post(configs.urls.BASE_URL + "/invoice/get", { id: id })
      .then((res) => {
        if (res.data.success) {
          selectRecord(res.data.response);
        }
      });
  };

  React.useEffect(() => {
    if (billId) {
      selectRecord(records.find((record: any) => record.id == billId));
    }
  }, [billId]);

  React.useEffect(() => {
    if (!isGlobalContext) {
      getRecords(billId);
      fetchExternalAccounts();
    }
  }, []);

  return {
    selectedRecord,
    records,
    setRecords,
    selectRecord,
    getRecords,
    externalAccounts,
    fetchExternalAccounts,
    recordsReceivable,
    recordsLoading,
    getBillWithId,
    setExternalAccounts,
    totalRecords,
    contactsLoading,
  };
};
