import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { configs } from "../../../constants";

export const useBanks = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [banks, setBanks] = React.useState<any[]>([]);
  const [selectedBank, setSelectedBank] = React.useState<any>();
  const [transactions, setTransaction] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [uploadModal, showUploadModal] = React.useState(false);

  const fetchLinkedBanks = async () => {
    setLoading(true);
    const banksResponse = await axios.get(
      configs.urls.BASE_URL + "/cashflow/balanceHistoryBank/getAll",
      {
        withCredentials: true,
      }
    );
    setLoading(false);
    if (banksResponse.data.success) {
      let banks = banksResponse.data.response;
      setBanks(banks);
    }
  };

  useEffect(() => {
    fetchLinkedBanks();
  }, []);

  //
  useEffect(() => {
    const bankId = searchParams.get("selectedBankId" || "");
    if (bankId) {
      const bank = banks.find((bank: any) => bank.bankId === bankId);
      if (bank) {
        setSelectedBank(bank);
        setTransaction(bank.transactions || []);
      }
    }
  }, [searchParams, banks]);

  return {
    banks,
    transactions,
    searchParams,
    setSearchParams,
    selectedBank,
    loading,
    fetchLinkedBanks,
    uploadModal,
    showUploadModal,
    setSelectedBank,
  };
};
