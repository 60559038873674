import {
  ActionIcon,
  Checkbox,
  LoadingOverlay,
  Select,
  Text,
} from "@mantine/core";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import CustomTabs from "../../common/PageLayout/components/CustomTabs";
import { COLORS } from "../../constants/theme";
import { formatCurrency } from "../../modules";
import { useAuth } from "../../contexts";
import { filterContacts } from "../../repositories/contactRepository";
import _ from "lodash";
import { SelectStyles } from "../../constants/styledComponents";
import {
  deleteTransactions,
  mapTxnsToContacts,
} from "../../repositories/transactions";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useBanksContext } from "./context/BanksContext";
import { Button, IconButton } from "rsuite";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import { ICONS } from "../../constants";
import UploadCSVModal from "../Cashflow/components/EditableTableRow/UploadCSVModal";
import SelectContact from "./components/SelectContact";
import Card from "../../common/Card/Card";

function TransactionsTable({ isViewOnly }: any) {
  const [loading, setLoading] = React.useState(false);
  const {
    transactions: _transactions,
    selectedBank,
    fetchLinkedBanks,
    uploadModal,
    showUploadModal,
  } = useBanksContext();
  const [transactions, setTransactions] = React.useState<any>(_transactions);
  const [error, setError] = React.useState("");
  const { externalAccounts } = useAuth();

  // useEffect(() => {
  //   if (transactions.length === 0) {
  //     setError("No transactions found");
  //   } else {
  //     setError("");
  //   }
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 300);
  // }, [_transactions]);

  useEffect(() => {
    if (_transactions) {
      let modifiedTxns = _transactions.map((txn: any) => {
        let contactTypes = getContactTypes(txn?.contact?.displayName);
        return {
          ...txn,
          contact: txn?.contact || undefined,
          selectedContact: undefined,
          contactTypes: [],
          // selectedContactType: contactTypes[0]?.value || "",
          selectedContactType: txn?.selectedContactType,
          searchText: txn?.contact?.displayName || "",
        };
      });

      setTransactions(modifiedTxns);
    }
  }, [_transactions]);

  useEffect(() => {
    console.log("On Change Transactions >>> ", transactions);
  }, [transactions]);

  const getContactTypes = (searchText: string) => {
    let contact = externalAccounts.find(
      (contact: any) => contact.displayName === searchText
    );

    if (contact) {
      let types = contact.types.map((type: string) => ({
        value: type,
        label: type,
      }));

      return types;
    } else return [];
  };

  const onSave = async () => {
    let txnsWithContacts = transactions.map((txn: any) => {
      let contact = externalAccounts.find(
        (contact: any) => contact.displayName === txn.searchText
      );

      return {
        ...txn,
        contact: contact
          ? {
              ...contact,
              invoices: [],
            }
          : undefined,
      };
    });
    setLoading(true);

    // console.log("transactions", txnsWithContacts);

    const { success } = await mapTxnsToContacts({
      transactions: txnsWithContacts,
    });
    // setTimeout(() => {
    setLoading(false);
    // }, 1500);
    fetchLinkedBanks();

    if (success) {
      showNotification({
        color: "green",
        message: `Changes saved successfully!`,
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 2000,
      });
    } else {
      showNotification({
        color: "red",
        message: `Error saving changes!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedTxns, setSelectedTxns] = useState<string[]>([]);

  useEffect(() => {
    // Update the selectAll state when the selectedTxns change
    setSelectAll(
      selectedTxns.length > 0 && selectedTxns.length === transactions.length
    );
  }, [selectedTxns, transactions]);

  useEffect(() => {
    console.log("selectedBank transactions table >>> ", selectedBank);
  }, [selectedBank]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    txnId: string
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedTxns([...selectedTxns, txnId]);
    } else {
      setSelectedTxns(selectedTxns.filter((id) => id !== txnId));
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedTxns(transactions.map((txn: any) => txn.id));
    } else {
      setSelectedTxns([]);
    }
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete the selected " +
          selectedTxns.length +
          " transactions(s)?"
      )
    ) {
      const { success } = await deleteTransactions({
        ids: selectedTxns,
        bankId: selectedBank?.bankId,
      });

      if (success) {
        showNotification({
          title: "Success",
          message: "Transactions(s) deleted successfully!",
          color: "green",
        });
        fetchLinkedBanks();
      } else {
        showNotification({
          title: "Error",
          message: "Could not delete transactions(s)",
          color: "red",
        });
      }
    }
  };

  return (
    <Fragment>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between ">
        <BankTitle style={{ flex: 1, fontSize: 24 }}>
          {selectedBank?.bankName}
        </BankTitle>
        <div className="is-flex is-flex-direction-row is-align-items-flex-end is-gap-1">
          <div
            style={{
              alignSelf: "flex-end",
              whiteSpace: "nowrap",
              fontWeight: "600",
              fontSize: 12,
            }}
          >
            {selectedTxns.length > 0
              ? selectedTxns.length + " selected"
              : transactions.length + " transactions"}
          </div>
          <div>
            <Button
              disabled={isViewOnly}
              style={{
                backgroundColor: isViewOnly
                  ? COLORS.btnDisabled
                  : COLORS.greenBlue,
                color: "white",
                width: 40,
                height: 38,
              }}
              onClick={() => showUploadModal(true)}
            >
              <UploadIcon src={ICONS.upload} alt="upload" />
            </Button>
          </div>
          <PrimaryButton
            style={{
              backgroundColor: isViewOnly
                ? COLORS.btnDisabled
                : COLORS.greenBlue,
            }}
            disabled={!selectedTxns.length || isViewOnly}
            onClick={handleDelete}
          >
            Delete
          </PrimaryButton>
          <PrimaryButton
            style={{
              backgroundColor: isViewOnly
                ? COLORS.btnDisabled
                : COLORS.greenBlue,
            }}
            disabled={isViewOnly}
            onClick={onSave}
            loading={loading}
          >
            Save to cashflow
          </PrimaryButton>
        </div>
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <div className="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"></div>
        <LoadingOverlay
          visible={loading}
          loaderProps={{
            color: COLORS.greenBlue,
            variant: "oval",
          }}
        />
        <Card
          className="table-container mb-3"
          style={{
            position: "relative",
          }}
        >
          {transactions.length === 0 ? (
            <NoTxn>No transactions found for this bank.</NoTxn>
          ) : null}
          <div
            className="table-container custom-scrollbar mb-3"
            style={{
              height: "calc(100vh - 280px)",
              overflowY: "scroll",
            }}
          >
            <table
              style={{ borderTop: "1px solid #e0e0e0" }}
              className="table is-bordered is-striped is-hoverable is-fullwidth is-relative "
            >
              <thead>
                <tr
                  style={{
                    fontWeight: "700",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 9,
                  }}
                >
                  <TableHeader>
                    <Checkbox
                      disabled={isViewOnly}
                      indeterminate={
                        selectedTxns.length > 0 &&
                        selectedTxns.length < transactions.length
                      }
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </TableHeader>
                  <TableHeader>Date </TableHeader>
                  <TableHeader>Description</TableHeader>
                  <TableHeader>Contact</TableHeader>
                  <TableHeader>Contact type</TableHeader>
                  <TableHeader>Amount</TableHeader>
                  <TableHeader>Currency</TableHeader>
                  <TableHeader>Running Balance</TableHeader>
                </tr>
              </thead>
              <tbody>
                {transactions
                  .sort(
                    (a: any, b: any) =>
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                  )
                  .map((transaction: any) => {
                    let contactTypes = getContactTypes(transaction.searchText);
                    let defaultType = contactTypes.length
                      ? contactTypes[0]
                      : {};
                    const isChecked = selectedTxns.includes(transaction.id);

                    return (
                      <tr key={transaction.id}>
                        <TableData style={{ textAlign: "center", width: 20 }}>
                          <Checkbox
                            disabled={isViewOnly}
                            styles={{
                              body: {
                                paddingRight: 0,
                              },
                            }}
                            checked={isChecked}
                            onChange={(event) =>
                              handleCheckboxChange(event, transaction.id)
                            }
                          />
                        </TableData>
                        <TableData className="p-3">
                          {moment(transaction.date).format("MMM DD, YYYY")}
                        </TableData>
                        <TableData
                          className="p-3"
                          width={20}
                          title={transaction.counterparty_name}
                          style={{
                            minWidth: 400,
                            // whiteSpace: "nowrap",
                            // overflow: "hidden",
                            // textOverflow: "ellipsis",
                          }}
                        >
                          {!transaction.counterparty_name
                            ? "N/A"
                            : transaction.counterparty_name}
                        </TableData>
                        <SelectContact
                          isViewOnly={isViewOnly}
                          item={transaction}
                          onChangeContact={(contact) => {
                            // let contactTypes = getContactTypes(value);
                            let contactTypes =
                              contact?.types.map((type: string) => ({
                                value: type,
                                label: type,
                              })) || [];

                            // console.log("contactTypes", contactTypes);
                            setTransactions((prev: any) => {
                              const modifiedTxns = prev.map((txn: any) => {
                                if (txn.id === transaction.id) {
                                  return {
                                    ...txn,
                                    searchText: contact?.displayName,
                                    selectedContactType: contactTypes.length
                                      ? contactTypes[0]?.value
                                      : "",
                                  };
                                }
                                return txn;
                              });
                              return modifiedTxns;
                            });
                          }}
                          onChangeType={(value: string) => {
                            console.log("Contact change value >>> ", value);
                            setTransactions((prev: any) => {
                              const modifiedTxns = prev.map((txn: any) => {
                                if (txn.id === transaction.id) {
                                  return {
                                    ...txn,
                                    selectedContactType: value,
                                  };
                                }
                                return txn;
                              });
                              return modifiedTxns;
                            });
                          }}
                        />

                        <TableData className="p-3">
                          {transaction.amount}
                        </TableData>
                        <TableData className="p-3">
                          {transaction.currency}
                        </TableData>
                        <TableData className="p-3">
                          {formatCurrency(
                            parseFloat(transaction?.running_balance || "0"),
                            2
                          )}
                        </TableData>
                      </tr>
                    );
                  })}
              </tbody>
              {/* No content */}
            </table>
          </div>
        </Card>
      </div>

      <UploadCSVModal
        open={uploadModal}
        onClose={(uploadDone) => {
          showUploadModal(false);
          if (uploadDone) {
            fetchLinkedBanks();
          }
        }}
        bank={{ name: selectedBank?.bankName, id: selectedBank?.bankId }}
      />
    </Fragment>
  );
}

export default TransactionsTable;

const TableHeader = styled.th`
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.brownGrey};
  text-align: left;
  height: 20px;
  vertical-align: middle;
  /* :first-child {
    min-width: 120px;
  } */
`;

const TableData = styled.td`
  font-size: 14px;
  text-align: left;
  /* :sec-child {
    min-width: 120px;
  } */
`;

const StyledSelect = styled(Select)`
  .mantine-Select-input {
    font-size: 14px;

    ::placeholder {
      color: #a30202fc;
    }
  }
`;

const UploadIcon = styled.img`
  width: 17px;
  height: 20px;
`;

const BankTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
`;

const NoTxn = styled.div`
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
