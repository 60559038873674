import moment from "moment";
import { getUserLS } from "./localStorage";

export const getDateRangeMonths = (isLineChart?: boolean) => {
  const { isDemo, createdAt } = getUserLS();
  
  let monthsSubtracted = isDemo ? 7 : 1;

  const latestDate = new Date().toISOString();

  const currDate = moment(latestDate).subtract(monthsSubtracted, "M");
  const dateEnd = moment(currDate).add(1, "M").add(1, "year");

  const dateStart = moment(latestDate)
    .subtract(monthsSubtracted, "M")
    .add(1, "M");
  const timeValues = [];
  while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
    timeValues.push(dateStart.format("MM YYYY"));
    dateStart.add(1, "month");
  }
  const timeValMonth = getMonthNamesFromNumber(timeValues);
  return {
    from: currDate.format("YYYY-MM-DD"),
    to: dateEnd.format("YYYY-MM-DD"),
    months: timeValMonth,
  };
};

const getMonthNamesFromNumber = (timeValues: string[]) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return timeValues.map((date) => {
    const monthVal = Number(date.split(" ")[0]);
    const yearVal = date.split(" ")[1];
    const monthString = month[monthVal - 1];
    return `${monthString} '${yearVal.split("").splice(2).join("")} `;
  });
};

export const getMonthNameFromNumber = (monthYear: string) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthVal = Number(monthYear.split(" ")[0]);
  const yearVal = monthYear.split(" ")[1];
  const monthString = month[monthVal - 1];
  return `${monthString} ${yearVal}`;
};
export const getValuesFromObjectArray = (array: any, key: string) => {
  return array.map((val: any) => val[key]);
};

export const getSpecificValueFromQb = (rows: any, _group: string) => {
  let val = "";
  rows?.forEach(({ type, group, Summary }: any = {}) => {
    if (type === "Section" && group === _group) {
      val = Summary.ColData[1].value;
    }
  });
  return val;
};
export const getAllRowsFromQb = (rows: any, _group: string) => {
  let val = "";
  rows?.forEach(({ type, group, Rows }: any = {}) => {
    if (type === "Section" && group === _group) {
      val = Rows.Row;
    }
  });
  return val;
};

export const getSummaryColData = (rows: any, _group: string) => {
  let val = "";
  rows?.forEach(({ type, group, Summary }: any = {}) => {
    if (type === "Section" && group === _group) {
      val = Summary.ColData[1].value;
    }
  });
  return val;
};

export const getValueRangesFromQb = (rows: any, _group: string) => {
  let val: number[] = [];
  rows?.forEach(({ type, group, Summary }: any = {}) => {
    if (type === "Section" && group === _group) {
      const summary = Summary.ColData;
      const summaryArrayFromOne = summary.slice(1, summary.length);

      val = getValuesFromObjectArray(summaryArrayFromOne, "value");
    }
  });
  return val;
};
export const getAllExpensesFromQb = (rows: any) => {
  if (!rows || !rows.length) {
    return [];
  }
  const res = rows?.map(({ type, group, Summary, ColData }: any = {}) => {
    if (!Summary) {
      return { [ColData[0].value]: ColData[1].value };
    }
    const key = Summary.ColData[0].value;
    const updatedKey = key.replace("Total ", "");
    return { [updatedKey]: Summary.ColData[1].value };
  });
  return res;
};
