import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Select } from "@mantine/core";
import { useAuth } from "../../../contexts";
import { filterContacts } from "../../../repositories/contactRepository";
import _ from "lodash";

interface Props {
  onChangeContact: (contact: any) => void;
  item: any;
  onChangeType: (value: string) => void;
  isViewOnly: boolean;
}
const SelectContact: React.FC<Props> = ({
  item,
  onChangeContact,
  onChangeType,
  isViewOnly,
}) => {
  const { externalAccounts } = useAuth();

  const [contacts, setContacts] = useState<any[]>(externalAccounts);
  const [currentTextEdit, setCurrentTextEdit] = useState("");

  const [options, setOptions] = useState<any>([]);
  const [contactTypes, setContactTypes] = useState<any>([]);

  const initializeOptions = () => {
    setOptions(
      contacts.map((account: any) => ({
        value: account.displayName,
        label: account.displayName,
        id: account.id,
      }))
    );
  };

  useEffect(() => {
    setContacts(externalAccounts);
  }, [externalAccounts]);

  useEffect(() => {
    initializeOptions();
  }, [contacts]);

  useEffect(() => {
    if (item?.contact) {
      setContactTypes(
        item.contact?.types?.map((type: string) => ({
          value: type,
          label: type,
        })) || []
      );
    }
    // setContactTypes(item?.types?.map((type: string) => ({
    //   value: type,
    //   label: type,
    // })) || [])
  }, [item]);

  useEffect(() => {
    if (!currentTextEdit) {
      setContacts(externalAccounts);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      const filteredContacts = await filterContacts(currentTextEdit);
      setContacts(_.uniqBy(filteredContacts, "id"));

      // Only update options if currentTextEdit is empty or if the selected value is different
      // setOptions(_.uniqBy(filteredContacts, "id"));
      // setExternalAccounts((prev) => _.uniqBy(filteredContacts, "id"));
    }, 0);

    return () => clearTimeout(delayDebounceFn);
  }, [currentTextEdit]);

  return (
    <Fragment>
      <TableData className="p-3">
        <StyledSelect
          disabled={isViewOnly}
          value={item.searchText}
          placeholder={!item.searchText ? "Uncategorized" : ""}
          data={options}
          nothingFound={
            item.searchText === "" ? "Type to search contacts" : "Nothing found"
          }
          searchable
          creatable={false}
          clearable
          onChange={(value: string) => {
            let contact = contacts.find(
              (contact: any) => contact.displayName === value
            );
            setContactTypes(
              contact?.types?.map((type: string) => ({
                value: type,
                label: type,
              })) || []
            );
            onChangeContact(contact);
            setCurrentTextEdit("");
            initializeOptions();
          }}
          onSearchChange={(value: string) => {
            setCurrentTextEdit(value);
          }}
          filter={(value, item) =>
            item.value.toLowerCase().includes(value.toLowerCase().trim())
          }
          styles={{
            input: {
              fontSize: 12,
            },
          }}
        />
      </TableData>
      <TableData className="p-3">
        <StyledSelect
          disabled={isViewOnly}
          data={contactTypes}
          defaultValue={item?.selectedContactType || contactTypes![0]?.value}
          value={item?.selectedContactType}
          placeholder={!item.searchText ? "Uncategorized" : ""}
          onChange={(value: string) => {
            onChangeType(value);
          }}
        />
      </TableData>
    </Fragment>
  );
};

export default SelectContact;

const StyledSelect = styled(Select)`
  .mantine-Select-input {
    font-size: 14px;

    ::placeholder {
      color: #a30202fc;
    }
  }
`;

const TableData = styled.td`
  font-size: 14px;
  text-align: left;
  /* :sec-child {
    min-width: 120px;
  } */
`;
