import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts";
import { CollapsedSidebarItem } from "./components/CollapsedSidebarItem";
import { IMAGES } from "../../constants";
import { getAvatarPlaceholder } from "../../modules/getAvatarPlaceholder";
import { COLORS } from "../../constants/theme";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { isUserViewOnly } from "../../modules/localStorage";
import { Fragment } from "react";
import { PLANS, Tier } from "../../constants/globalConstants";

function Sidebar({ sidebarCollapsed }: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const { user, organization } = useAuth();

  const isViewOnly = isUserViewOnly();

  const plan =
    PLANS.find((plan) => plan.tier === organization?.subscription?.tier) ||
    ({} as any);

  const onUpgrade = () => {
    navigate("/pricing");
  };

  return (
    <div style={{ top: 0, zIndex: 5 }}>
      <div
        style={{
          maxWidth: 90,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <figure
            style={{
              margin: 10,
              marginBottom: 10,
              width: "50px",
              height: "50px",
            }}
            className="is-rounded"
          >
            <img
              onClick={() => {
                if (isViewOnly) {
                  return;
                }
                navigate("/get-started");
              }}
              src={"/assets/images/tsc-logo.png"}
              alt="tsc-logo"
              title="The Scalable CFO"
            />
          </figure>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflowY: "auto",
            alignItems: "center",
          }}
          className="custom-scrollbar-is-thin"
        >
          <CollapsedSidebarItem
            title={"Cashflow"}
            path={"cashflow"}
            icon={IMAGES.cashflow}
            defaultTab="forecast"
            active={location.pathname === "/cashflow"}
          />
          {!isViewOnly && (
            <Fragment>
              <CollapsedSidebarItem
                title={"Bank"}
                path={"banks"}
                icon={"/assets/images/bank.svg"}
                // defaultTab="all"
                active={location.pathname === "/banks"}
              />
              <CollapsedSidebarItem
                title={"Pay"}
                path={"pay"}
                icon={IMAGES.pay}
                defaultTab="all"
                active={location.pathname === "/pay"}
              />
              <CollapsedSidebarItem
                title={"Get Paid"}
                path={"get-paid"}
                icon={IMAGES.get_paid}
                defaultTab="all"
                active={location.pathname === "/get-paid"}
              />

              <CollapsedSidebarItem
                title={"Contacts"}
                path={"contacts"}
                icon={IMAGES.contacts}
                defaultTab="all"
                active={location.pathname === "/contacts"}
              />
            </Fragment>
          )}

          <CollapsedSidebarItem
            title={"Graph"}
            path={"companies"}
            defaultTab="all"
            icon={IMAGES.company}
            active={location.pathname === "/companies"}
          />

          {organization?.isDemo ||
            (plan.tier === Tier.Demo && (
              <PrimaryButton
                className={"mb-3 py-4 is-small mt-2 "}
                onClick={onUpgrade}
                style={{
                  borderRadius: 5,
                }}
              >
                Upgrade
              </PrimaryButton>
            ))}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <CollapsedSidebarItem
            title={"Your CFO"}
            path={"your-cfo"}
            icon={IMAGES.hire}
            active={location.pathname === "/your-cfo"}
          /> */}
          {/* {!isViewOnly && ( */}
          <CollapsedSidebarItem
            isViewOnly={isViewOnly}
            title={"Settings"}
            path={"settings"}
            icon={IMAGES.settings}
            active={location.pathname === "/settings"}
          />
          {/* )} */}
          {isViewOnly ? (
            <figure
              style={{
                margin: 8,
                marginBottom: 10,
                height: 70,
                width: 70,
                borderRadius: "50%",
                border:
                  location.pathname === "/get-started"
                    ? `4px solid ${COLORS.greenBlue}`
                    : "2px solid #91c3be",
                // animation on border

                transition: "border 0.3s ease-in-out",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              title={user?.firstName + " " + user?.lastName}
            >
              <img
                style={{
                  height: "100%",
                  borderRadius: "50%",
                  width: "100%",
                  padding: 2,
                }}
                className="is-rounded"
                src={getAvatarPlaceholder(user?.firstName)}
                alt="avatar"
              />
            </figure>
          ) : (
            <Link to={{ pathname: "/get-started" }}>
              <figure
                style={{
                  margin: 8,
                  marginBottom: 10,
                  height: 70,
                  width: 70,
                  borderRadius: "50%",
                  border:
                    location.pathname === "/get-started"
                      ? `4px solid ${COLORS.greenBlue}`
                      : "2px solid #91c3be",
                  // animation on border

                  transition: "border 0.3s ease-in-out",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                title={user?.firstName + " " + user?.lastName}
              >
                <img
                  style={{
                    height: "100%",
                    borderRadius: "50%",
                    width: "100%",
                    padding: 2,
                  }}
                  className="is-rounded"
                  src={getAvatarPlaceholder(user?.firstName)}
                  alt="avatar"
                />
              </figure>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
